<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Account</a>
          </li>
          <li class="breadcrumb-item active">
            Billing
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'dashboard'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <div class="section-header-title-group">
              <h1>Billing</h1>
              <p>Add your billing details for your account here</p>
            </div>
          </div>
        </div>
        <div class="row row-has-panel">
          <div class="col-xl-8">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Billing Details</h3>
                </div>
                <div class="section-panel-header-action">
                  <a
                    v-if="statusEdit"
                    href="#"
                    class="btn btn-link"
                    @click.prevent="cancelEditingBillingDetails"
                  >
                    <icon-cross class="mr-2 d-flex" />
                    Cancel
                  </a>
                  <a
                    v-else
                    href="#"
                    class="btn btn-link"
                    @click.prevent="startEditBillingDetails"
                  >
                    <icon-edit class="mr-2 d-flex" />
                    Edit
                  </a>
                </div>
              </div>
              <div class="section-panel-body">
                <billing-details-form
                  :errors="errors"
                  :status-edit="statusEdit"
                  @billing-details-loaded="onBillingDetailsLoaded"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Payment Method</h3>
                </div>
                <div class="section-panel-header-action">
                  <a
                    class="btn btn-link"
                    @click.prevent="showPaymentMethodModal"
                  >
                    <icon-plus-primary class="mr-2 d-flex" />
                    {{ curPaymentMethod ? 'Change' : 'Add' }}
                  </a>
                </div>
              </div>

              <div class="section-panel-body">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="col-12">
                      <div class="payment-information payment-information-border bg-white p-3 mt-0">
                        <div
                          v-if="curPaymentMethod"
                          class="row p-4"
                        >
                          <div class="col-12 mt-3 my-2">
                            <h4>**** **** **** {{ curPaymentMethod.mask }}</h4>
                          </div>
                          <div class="col-9 my-2">
                            <span>
                              Expires on:
                              <span class="font-weight-bold">
                                {{ expiredDate | moment('MMM YYYY') }}
                              </span>
                            </span>
                          </div>
                          <div class="col-3 my-2">
                            <img
                              v-if="curPaymentMethod.brand === 'mastercard'"
                              src="@/assets/img/mastercard_logo.svg"
                              class="img-fluid"
                              alt=""
                            >
                            <img
                              v-if="curPaymentMethod.brand === 'visa'"
                              src="@/assets/img/visa_logo.svg"
                              class="img-fluid"
                              alt=""
                            >
                          </div>
                        </div>
                        <div
                          v-else
                          class="row"
                        >
                          <div class="col-12 my-2">
                            <p>No payment method added</p>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 text-right mt-1">
                        <a
                          v-if="curPaymentMethod"
                          v-tooltip="{ content: 'Delete payment method'}"
                          href="#"
                          class="btn btn-link"
                          @click.prevent="showConfirmDelete"
                        >
                          <icon-trash-primary class="mr-2" />
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Upcoming invoice</h3>
                </div>
                <div class="section-panel-header-action" />
              </div>
              <div class="section-panel-body">
                <div v-if="isFetchingUpcomingInvoice">
                  <span class="fa fa-cog fa-spin mr-1" /> Loading...
                </div>
                <div v-else>
                  <div v-if="upcomingInvoice.current_period_end">
                    <strong>
                      Next invoice:
                    </strong>
                    {{ upcomingInvoice.current_period_end }}
                    <br>
                    <br>
                    <strong>
                      Estimated amount:
                    </strong>
                    {{ formatMoney(upcomingInvoice.upcoming_amount) }}
                  </div>
                  <div v-else>
                    There is no upcoming invoice
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="statusEdit"
          class="row"
        >
          <div class="col-lg-12">
            <hr>
            <div class="text-right">
              <a
                href="#"
                class="btn mr-2"
                @click.prevent="cancelEditingBillingDetails()"
              >Revert Changes</a>
              <a
                href="#"
                class="btn btn-primary"
                @click.prevent="saveBillingDetails()"
              >Save &amp; Update</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <payment-method-modal
      v-if="isShowPaymentModal"
      :go-back-button-text="'Close'"
      @close-payment-modal="closePaymentMethodModal"
      @go-back="closePaymentMethodModal"
      @payment-method-saved="selectDefaultPayment"
    />

    <confirm-delete-recording
      v-if="isShowConfirmDelete"
      :title="curTitle"
      :content="curContent"
      @close="isShowConfirmDelete = false"
      @delete="deletePaymentMethod"
    />
    <confirm-dialog
      v-if="isShowConfirmDialog"
      title="Are you sure you want to set current payment method to default?"
      description="Payments will be taken with this payment method."
      @no="isShowConfirmDialog = false"
      @yes="setDefaultPaymemt"
    />
  </main>
</template>
<script>
// import Multiselect from 'vue-multiselect';
import BillingDetailsForm from '@/components/PaymentModals/BillingDetailsForm.vue';
import ConfirmDeleteRecording from '@/components/ConfirmDeleteRecording.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import PaymentMethodModal from '@/components/PaymentModals/PaymentMethodModal.vue';
import PaymentMethodModalMixin from '@/mixins/PaymentMethodModalMixin';
import IconEdit from '@/components/icons/IconEdit.vue';
import IconCross from '@/components/icons/IconCross.vue';
import IconPlusPrimary from '@/components/icons/IconPlusPrimary.vue';
import IconTrashPrimary from '@/components/icons/IconTrashPrimary.vue';
import axios from 'axios';
import FormatMoneyMixin from '@/mixins/FormatMoneyMixin';
import {billingStore} from '@/store/__STORE_billing';

export default {
  components: {
    // Multiselect,
    BillingDetailsForm,
    ConfirmDeleteRecording,
    ConfirmDialog,
    IconEdit,
    IconCross,
    IconPlusPrimary,
    IconTrashPrimary,
    PaymentMethodModal,
  },
  mixins: [PaymentMethodModalMixin, FormatMoneyMixin],
  data() {
    return {
      statusEdit: false,
      errors: {},

      expiredDate: '',

      isShowModal: false,
      curTitle: 'Are you sure you want to delete current payment method?',
      curContent: '',
      isShowConfirmDelete: false,
      curPaymentMethod: '',
      isShowConfirmDialog: false,

      isFetchingUpcomingInvoice: true,
      upcomingInvoice: {},
      originalBillingDetails: {},
      billingDetailsLoading: true,
    };
  },
  computed: {

    billingDetails: () => billingStore.billingDetails,
    paymentMethods: () => billingStore.paymentMethods,

  },
  watch: {
    curPaymentMethod(val) {
      if (val) {
        this.expiredDate = this.$moment(`${val.exp_month}/${val.exp_year}`, 'M/YYYY');
      } else {
        this.expiredDate = '';
      }
    },
  },
  created() {
    this.fetchUpcomingInvoice();
    billingStore.getPaymentMethods().then(() => {
      this.selectDefaultPayment();
    });
  },
  methods: {
    startEditBillingDetails() {
      if (this.billingDetailsLoading) {
        return;
      }

      this.statusEdit = true;
    },
    cancelEditingBillingDetails() {
      if (this.billingDetailsLoading) {
        return;
      }

      this.statusEdit = false;
      billingStore.setBillingDetails(this.originalBillingDetails);
    },
    onBillingDetailsLoaded(billingDetails) {
      this.billingDetailsLoading = false;
      this.originalBillingDetails = {...billingDetails};
    },
    fetchUpcomingInvoice() {
      axios({url: '/upcoming-invoice', method: 'GET'}).then((response) => {
        this.isFetchingUpcomingInvoice = false;
        this.upcomingInvoice = response.data;
      }).catch((err) => {

      });
    },
    saveBillingDetails() {
      this.billingDetailsLoading = true;
      billingStore.updateBillingDetails(this.billingDetails).then(() => {
        this.$toast.success('The billing details updated successfully!');
        this.errors = {};
        this.originalBillingDetails = {...this.billingDetails};
        this.billingDetailsLoading = false;
        this.statusEdit = false;
      }).catch((err) => {
        this.billingDetailsLoading = false;
        try {
          const data = err.response.data;
          this.$toast.error(data.message);
          if (err.response.status === 422) {
            this.errors = data.errors;
          }
        } catch {
          return;
        }
      });
    },
    showConfirmDelete() {
      this.isShowConfirmDelete = true;
    },
    deletePaymentMethod() {
      const vm = this;
      billingStore.deletePaymentMethods(this.curPaymentMethod.id).then(() => {
        vm.$toast.success('Your payment method is deleted successfully!');
        vm.isShowConfirmDelete = false;
        billingStore.getPaymentMethods().then(() => {
          this.selectDefaultPayment();
        });
      }).catch((err) => {
        try {
          const data = err.response.data;
          vm.$toast.error(data.message);
        } catch {
          1;
        }
        this.isShowConfirmDelete = false;
      });
    },
    selectDefaultPayment() {
      this.curPaymentMethod = this.paymentMethods.find((element) => element.is_default);
    },
    showConfirmDialog() {
      this.isShowConfirmDialog = true;
    },
    setDefaultPaymemt() {
      const vm = this;
      billingStore.setDefaultPayment( this.curPaymentMethod.id).then(() => {
        vm.$toast.success('Your default payment method is set successfully!');
        vm.isShowConfirmDialog = false;
        billingStore.getPaymentMethods().then(() => {
          this.selectDefaultPayment();
        });
      }).catch((err) => {
        try {
          const data = err.response.data;
          vm.$toast.error(data.message);
        } catch {
          1;
        }
        this.isShowConfirmDialog = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__variables";

.payment-information-border {
  border: 1px solid $inactive-color;
  border-radius: 8px;
}
</style>
